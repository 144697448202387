<template>
  <div>
    <van-popup :close-on-click-overlay="false" v-model="show">
          <p class="online-service">
          <a href="http://mp.s11.cn/newb2c/productdetail/31775987">
          点击购买</a>
        </p>
        <br>
      <h2>确认会员身份</h2>
      <div class="content">
        <input
                ref="testDom"
                maxlength="11"
                v-model="phoneNum"
                type="tel"
                placeholder="请输入下单手机帐号 "
        />
      </div>
      <van-button @click="confirm" :color="showColor" round type="info" :disabled="disable"
      ><span>确认</span></van-button
      >
    </van-popup>
  </div>
</template>

<script>
import { mapState,mapActions} from 'vuex'
  export default {
    name: "Message",
    data() {
      return {
        phoneNum: null,
        show: false,
        showColor: "#a7a7a7",
        phoneVerify: 19812005147,
        num: 0,
        disable:false,
        loginForm:{
          username:"szxx"
        },
        validateForm:{
          token:"",
          phone:""
        }
      };
    },
    computed:{
       ...mapState(['token','eligibilityId'])
    },
    beforeMount() {
      this.show = true;
    },

    methods: {
      // ...mapMutations(['setToken']),
      ...mapActions(['asyncSetToken','asyncSetEligibilityId','asyncSetPhone']),
      confirm() {
         this.validateForm.phone = this.$refs.testDom.value.trim();
        // let param = new URLSearchParams();
        // param.append("phone", phone);
        if(this.num==1){
          //按钮禁用,防止多次请求
          this.disable = true;
          this.axios
                .post('/accounts/loginByUsername',this.loginForm,{
                  headers:{
                    'Content-Type':'application/json'
                  }
                })
                .then(res => {
                  if(res.data.data.code != '10000'){
                    this.$notify(res.data.data.message);
                  }else{
                   // 存token 再次发送验证手机请求
                  this.asyncSetToken(res.data.data.token)
                  this.validateForm.token = res.data.data.token

                  this.axios.post('/eligibility/validate',this.validateForm,{
                    headers:{
                      'Content-Type':'application/json'
                    }
                  }).then(res => {
                    if(res.data.data.code != 20000){
                      this.$notify(res.data.data.message);
                    }else{
                         this.asyncSetEligibilityId(res.data.data.detail.id)
                         this.$emit('childFn',this.validateForm.phone);
                         this.show = false;
                         this.$toast(res.data.data.message);
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
                  }

              
                })
                .catch(error => {
                  console.log(error)
                })



          // this.axios
          //         .post('/etc/validate', param, {
          //           headers: {
          //             'Accept': '*/*',
          //             'Content-type': 'application/x-www-form-urlencoded'
          //           }
          //         })
          //         .then((res) => {
          //           let messages = res.data.data.message;
          //           let codes = res.data.data.code;
          //           if(codes==1){
          //             this.$emit('childFn',phone);
          //             this.show = false;
          //             this.$toast(messages);
          //           }else if (codes==0){
          //             this.$toast(messages);
          //           }
          //         })
          //         .catch((res) => {
          //           let message = res.data.data.message;
          //           this.$toast(message);
          //         });
        }
      },

      inputArea() {
        let inpLenght = this.$refs.testDom.value.trim().length;
        inpLenght == 11
                ? (this.showColor = "#d69d4c")
                : (this.showColor = "#a7a7a7");
      },
    },

    watch: {
      phoneNum(newPhone) {
        newPhone.length == 11
                ? (this.showColor = "#d69d4c")
                : (this.showColor = "#a7a7a7");
        newPhone.length == 11
                ? (this.num = 1)
                : (this.num = 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .van-popup--center {
    width: 84%;
    padding: 1rem;
    margin-top: -4.8rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: #fff;

    h2 {
      color: #222;
      font-size: 0.85rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .content {
      padding: 0.9rem 0;
      margin-bottom: 1rem;
      border-bottom: 1px solid #ebebeb;

      input {
        width: 100%;
        padding: 0;
        font-size: 1rem;
        text-align: center;
        vertical-align: middle;
        border: 0;
      }
    }

    .van-button--round {
      width: 100%;
      height: 2.8125rem;
      border: 0;
      border-radius: 2.25rem;
      font-size: 1rem;
      text-align: center;
    }
  }
  .online-service{
      width: 22%;
      float: right;
      cursor: pointer;
      line-height: 1rem;
      font-size: .7rem;
    }
    a{
      color: #0066ff;
    }
</style>