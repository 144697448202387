<template>
  <div class="box">
    <img src="../assets/image/zaiRui_bg.jpg" alt=""/>
    <div class="content-box1">
      <a @click="zaiRui_Week"><img src="../assets/image/zaiRui_Week.png"/></a>
    </div>
    <div class="content-box2">
      <a @click="zaiRui_D_Month"><img src="../assets/image/zaiRui_D_Month.png"/></a>
    </div>
    <div class="content-box3">
      <a @click="zaiRui_Month"><img src="../assets/image/zaiRui_Month.png"/></a>
    </div>
    <div class="content-box4">
      <a @click="zaiRui_Year"><img src="../assets/image/zaiRui_Year.png"/></a>
    </div>
    <div class="content-box5">
      <a @click="zaiRui_Year_Mini"><img src="../assets/image/year_mini.png"/></a>
    </div>


    <div class="rules">
      <div class="rules-lists">
        <div class="rules-title">· 权益购买须知 ·</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、每个手机号码限购1份，下单后立即激活会员特权。购买时务必填写核实正确的手机号码，如因手机号填写错误导致充值错误，由消费者自行承担。
          </p>
          <p class="rules-txt">2、本产品下单购买，不退不改，介意者勿拍！</p>
          <p class="rules-txt">
            3、下单前请您务必仔细阅读产品中各项说明，然后再下单订购。
          </p>
          <p class="rules-txt">
            4、下单购买后，则视为您已仔细阅读本产品中的各项说明，并认同此《购买须知》以及产品规则，否则责任自负，不在售后服务之列。
          </p>
        </div>
      </div>

      <div class="rules-lists">
        <div class="rules-title">兑换及使用规则</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、使用方法：购买韭菜会员后，微信打开搜索【再芮股份】微信公众号-【韭菜会员领取】轮播框-输入购买【手机号码】+【验证码】登录。即可兑换N大影音APP的特权VIP之一，通过兑换成功的手机号码登录对应的
            影音APP/官网，即可享受VIP权益。
          </p>
          <p class="rules-txt">
            2、使用规则:<br/>
            年卡：共有12个周期兑换VIP权益(即购买后12个月内有12次权益兑换机会)<br/>
            半年卡：共有6个周期兑换VIP权益(即购买后6个月内有6次权益兑换机会)<br/>
            季卡：共有3个周期兑换VIP权益(即购买后3个月内有3次权益兑换机会)<br/>
            月卡：共有1个周期兑换VIP权益(即购买后1个月内有1次权益兑换机会)<br/>
            钻石版月卡：共4个周期兑换VIP权益(即购买后1个月内有4次权益兑换机会)<br/>
            周卡：共有1个周期兑换VIP权益(即购买后1个星期内有1次权益兑换机会)<br/>
          </p>
          <p class="rules-txt">
            3、为确保个人权益与信息安全，在兑换权益周期内须本人填写【手机号码】+【验证码】的形式登录领取兑换；可以选择周期内任意一款产品，需每月验证本人使用去兑换，预期未兑换视为放弃兑换资格。
          </p>
        </div>
      </div>
      <div class="rules-lists">
        <div class="rules-title">· 注意事项 ·</div>
        <div class="rules-text">
          <p class="rules-txt">
            1、每个周期每次兑换会员时只能选择一个会员。
          </p>
          <p class="rules-txt">
            2、每次选择会员时务必填写购买时的手机号码; 腾讯视频、QQ音乐、QQ会员要求填写正确的QQ号码，该三项权益要求通过QQ账号登录。
          </p>
          <p class="rules-txt">
            3、本商品只能在手机、电脑、ipad上使用，均非TV版。
          </p>
          <p class="rules-txt">
            4、若24小时后还未激活，请及时联系客服。
          </p>
          <p class="rules-txt">
            5、每月N个会员可相互转换/持续兑换一种，每月限转1次。
          </p>
          <p class="rules-txt">
            6、仅限注册会员购买使用，无法为其他手机号购买，可领取的权益均非TV版。
          </p>
          <p class="rules-txt">
            7、套餐内容如有变动或需要套餐外增值服务，费用自理，详情请咨询客服。
          </p>
          <br/>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  name: "Login",
  computed: {},
  data() {
    return {};
  },
  created() {
  },
  methods: {
    zaiRui_Week() {
      window.location.href = "https://leekmember.zairui-vip.com/?index=jchy_week";
    },
    zaiRui_D_Month() {
      window.location.href = "https://leekmember.zairui-vip.com/?index=jchy_dmonth";
    },
    zaiRui_Month() {
      window.location.href = "https://leekmember.zairui-vip.com/?index=jchy_month";
    },
    zaiRui_Year() {
      window.location.href = "https://leekmember.zairui-vip.com/?index=jchy_year";
    },
    zaiRui_Year_Mini() {
      window.location.href = "https://leekmember.zairui-vip.com/?index=jchy_year_mini&version=1.0";
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 130vh;
    /* height: 100%;*/
    position: relative;
  }

  .content-box1 {
    width: 100%;
    position: absolute;
    top: 24%;
    left: 25%;

    img {
      width: 50%;
      height: 5%;
    }
  }

  .content-box2 {
    width: 100%;
    position: absolute;
    top: 41%;
    left: 25%;

    img {
      width: 50%;
      height: 5%;
    }
  }

  .content-box3 {
    width: 100%;
    position: absolute;
    top: 58%;
    left: 25%;

    img {
      width: 50%;
      height: 5%;
    }
  }

  .content-box4 {
    width: 100%;
    position: absolute;
    top: 75%;
    left: 25%;

    img {
      width: 50%;
      height: 5%;
    }
  }

  .content-box5 {
    width: 100%;
    position: absolute;
    top: 92%;
    left: 23%;

    img {
      width: 54%;
      height: 5%;
    }
  }

  /*.content-box5 {
    width: 100%;
    position: absolute;
    top: 286%;
    left: 20%;

    img {
      width: 60%;
      height: 5%;
    }
  }*/

  .content-box6 {
    width: 100%;
    position: absolute;
    top: 326%;
    left: 0.5%;

    img {
      width: 100%;
      height: 10%;
    }
  }

  .rules {
    background: #fff5ea;
    padding: 0 1.25rem;

    .rules-lists {
      padding-top: 1.5rem;

      .rules-title {
        color: #222;
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
      }

      .rules-text {
        .rules-txt {
          color: #666;
          font-size: 0.95rem;
          line-height: 1.6;
          margin-bottom: 0.25rem;
        }

        .txt-color {
          color: #bc7714;
        }
      }
    }
  }


}
</style>
