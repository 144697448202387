import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:{name:'Login'},
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/main',
    name: 'Index',
    component: Index,
    meta:{
      keepAlive:true
    }
  },

  {
    path: '/record/:id',
    name: 'Record',
    component: () => import(
      '../views/Record.vue'
   )
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  if(to.path === '/' || to.path === '/login') {
    next()
  }
  if(!sessionStorage.getItem("token")){
    if(to.path !== '/login'){
      document.title = "登录"
      window.location.href = `/login`
    }
  }else{
    next()
  }
})

export default router