import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:sessionStorage.getItem("token")|| '',
    eligibilityId:'',
    phone:''
  },
  mutations: {
    setToken(state,data){
      state.token = data
      sessionStorage.setItem("token",data) 
    },
    setEligibilityId(state,data){
      state.eligibilityId = data
    },
    setPhone(state,data){
      state.phone = data
    }

  },
  actions: {
    asyncSetToken(context,data){
      context.commit('setToken',data)
    },
    asyncSetEligibilityId(context,data){
      context.commit('setEligibilityId',data)
    },
    asyncSetPhone(context,data){
      context.commit('setPhone',data)
    }
  },
  modules: {
  }
})
